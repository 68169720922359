/* eslint-disable react/forbid-component-props */
import React, {useEffect, useState, Fragment} from 'react';
import {getCurrentUserFromApi, getEntitlementFromApi} from '../../api';
import {getLogoutUrl} from '../../config';
import {clearEntitlement} from '../../storage';
import {Logo} from '../Logo';
import {Spinner} from '../Spinner';
import {BaseModal} from '../BaseModal';
import styles from './styles.scss';

export const RestorePurchaseModal = ({restorePurchaseUrl, onClose}) => {
  const [entitlementFromApi, setEntitlementFromApi] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  const handleRestorePurchaseContinue = () => {
    location.href = restorePurchaseUrl;
  };

  const handleReloadPage = () => {
    clearEntitlement();
    location.reload();
  };

  const setupEmails = async () => {
    const [entitlementResponse, currentUserResponse] = await Promise.all([
      getEntitlementFromApi().catch(() => null),
      getCurrentUserFromApi()
    ]);

    setEntitlementFromApi(entitlementResponse);
    setCurrentUser(currentUserResponse);
  };

  useEffect(() => {
    setupEmails().catch(() => {});
  }, []);

  if (!currentUser) {
    return (
      <BaseModal
        content={<Spinner />}
        header={<Logo type='mailPlus' color='white' />}
        onClose={onClose}
      />
    );
  }

  const mailplusEmail = entitlementFromApi && entitlementFromApi.mailplusEmail;

  // allow_access means the user has mail + entitlement 'mailplus-basic'
  const isEntitled = entitlementFromApi && entitlementFromApi.allow_access;
  const molEmail = currentUser && currentUser.email;
  const logoutUrl = getLogoutUrl();

  if (isEntitled) {
    return (
      <BaseModal
        buttonText='Refresh page'
        content='Please click the button to refresh the page and access your subscription'
        header={
          <Fragment>
            <Logo type='mailPlus' color='white' />
            <div className={styles.contentSecondaryTitle}>
              You are already subscribed to Mail+
            </div>
          </Fragment>
        }
        onButtonClick={handleReloadPage}
        onClose={onClose}
      />
    );
  }

  return (
    <BaseModal
      buttonText='Continue to sign in'
      content='Please sign in with the email address and password used when purchasing your subscription'
      header={
        <Fragment>
          <Logo type='mailPlus' color='white' />
          <div className={styles.contentSecondaryTitle}>
            You may have subscribed with a different email address
          </div>
          {mailplusEmail &&
            <div className={styles.contentTextRegular}>
              The account below does not have a subscription<br />
              <span className={styles.contentTextItalic}>{mailplusEmail}</span>
            </div>
          }
        </Fragment>
      }
      footer={
        <div className={styles.footerTextItalic}>
          Not {molEmail}? Please <a href={logoutUrl}>log out now</a>
        </div>
      }
      onButtonClick={handleRestorePurchaseContinue}
      onClose={onClose}
    />
  );
};
