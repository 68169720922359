import {later} from '@mol-fe/mol-fe-async';
import {getEntitlementAndStore} from './api';
import {getCachedEntitlement, clearEntitlement} from './storage';
import {hasAdEntitlement} from './linkAccounts/constantsAndUtils';

export const refreshAndStoreEntitlement = async () => {
  const isPaywallEligible = window.getIsPaywallEligible && window.getIsPaywallEligible();

  // Currently all GDPR GEOs are paywall eligible, if this is changing in the future or
  // if we ever need to put consent pay in CCPA, then this if statement needs to be amended
  // in order for restoreEntitlementsCache to work correctly (hasAdEntitlement)
  if (!isPaywallEligible) {
    return;
  }

  if (!window.DM || !window.DM.isLoggedIn) {
    clearEntitlement();
    later.go('REFRESHED_PAYWALL_ENTITLEMENT');

    return;
  }

  if (window.location.search.match(/mailplusPurchaseOutcome/i) || window.mailplusPurchaseOutcome) {
    return;
  }

  if (!getCachedEntitlement()) {
    const entitlementFromApi = await getEntitlementAndStore().catch(() => null);

    // If we have entitlements and the context is not 'mailplusPurchaseOutcome' reload page
    if (hasAdEntitlement(entitlementFromApi)) {
      window.location.reload();
    }
  }

  later.go('REFRESHED_PAYWALL_ENTITLEMENT');
};
