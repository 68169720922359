import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import {pageEvents, fireBidmaxTrackingEvent} from '@mol-fe/mol-fe-tracking';
import {addUsedArticleIds} from './api';

export const MODULE_NAME = 'mol-fe-related-replace';
let contentViewed = false;

const numberToThousandReadable = (num) => {
  if (num < 1000) {
    return num;
  }

  return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}k`;
};

const buildArticle = ({article}) => {
  const {imgUrl, url, headline, viewCountTrending, isPaywalled} = article;
  const lozenges = [];

  if (isPaywalled) {
    lozenges.push('<span class="is-paywalled"></span>');
  }

  return `
    <a class="related-replace-article" href="${url}">
      <div class="related-replace-image">
        <img src="${imgUrl}" alt="" width="636" height="382" loading="lazy" />
      </div>
      <div class="related-replace-info">
        <div class="related-replace-headline">${lozenges.join('')}${headline}</div>
        ${viewCountTrending ? `
        <div class="related-replace-count-wrap">
          <div class="related-replace-count wocc">${numberToThousandReadable(viewCountTrending)} viewing now</div>
        </div>
        ` : ''}
      </div>
    </a>
  `;
};

const ARTICLE_COUNT_DESKTOP_XWIDE = 5;
const ARTICLE_COUNT_DEFAULT = 3;

const getArticleCount = () => {
  const {renderPlatform} = getPageMetadata();
  const {articleStyle} = getPageCriteria();
  const articleCount = renderPlatform === 'desktop' && articleStyle === 'extraWide' ?
    ARTICLE_COUNT_DESKTOP_XWIDE :
    ARTICLE_COUNT_DEFAULT;

  return articleCount;
};

const buildWidget = ({
  articles,
  instanceIndex = 0,
  topicGroup = null,
  isInlinePaywall = false
}) => {
  const articleCount = getArticleCount();
  const outerWidget = document.createElement('div');
  const widget = document.createElement('div');
  let trackId = `related-replace${instanceIndex > 0 ? `-${instanceIndex + 1}` : ''}`;

  widget.classList.add('mol-fe-related-replace');
  widget.classList.add('ccox');

  if (articleCount === ARTICLE_COUNT_DESKTOP_XWIDE) {
    widget.classList.add('related-replace-desktop-xwide');
  }

  if (topicGroup === 'royals') {
    outerWidget.classList.add('royal_wedding');
  }

  if (isInlinePaywall) {
    trackId = 'related-replace-paywall';
    outerWidget.classList.add('home');
    outerWidget.classList.add('related-replace-paywall');
  }

  widget.dataset.trackModule = trackId;

  const articleHtml = articles
    .slice(0, articleCount)
    .map((article) =>
      buildArticle({
        article
      })
    ).join('');

  widget.innerHTML = `
    <div class="related-replace-title wocc">${instanceIndex > 0 ? 'MORE ' : ''}TRENDING</div>
    <div class="related-replace-articles">
      ${articleHtml}
    </div>
  `;

  Array.from(widget.children[1].children).forEach((element, index) => {
    element.addEventListener('click', () => {
      const trackingData = {
        clickUrl: element.href,
        position: index + 1
      };

      fireBidmaxTrackingEvent('click', MODULE_NAME, trackingData);
      pageEvents.publish(pageEvents.RELATED_REPLACE_CLICK, trackingData);
    });
  });

  outerWidget.appendChild(widget);

  const observer = new IntersectionObserver((entries, obs) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        if (!contentViewed) {
          fireBidmaxTrackingEvent('viewable', MODULE_NAME);
          pageEvents.publish(pageEvents.RELATED_REPLACE_VIEWABLE);
          contentViewed = true;
        }
        obs.unobserve(widget);
      }
    }
  });

  observer.observe(widget);

  return outerWidget;
};

export const clearPlaceHolder = (relatedElement) => {
  if (relatedElement && relatedElement.classList.contains('related-replace-placeholder')) {
    relatedElement.remove();
  }
};

export const replaceRelated = ({relatedElement, articles, instanceIndex = 0, topicGroup = null, isInlinePaywall = false}) => {
  const articleCount = getArticleCount();

  if (!relatedElement || !articles || !articles.length || articles.length < articleCount) {
    clearPlaceHolder(relatedElement);

    return;
  }

  const widget = buildWidget({
    articles,
    instanceIndex,
    isInlinePaywall,
    topicGroup
  });

  relatedElement.replaceWith(widget);

  addUsedArticleIds(articles.slice(0, articleCount).map((article) => article.articleId));
};
