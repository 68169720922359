import React from 'react';
import {render} from 'react-dom';
import URL from 'url-parse';
import {getCurrentUserFromApi, getEntitlementFromApiWithRetry} from './api';
import {getPurchaseUrl, getRestorePurchaseUrl, getManageSubscriptionUrl} from './config';
import {ProfileSubscription} from './components/ProfileSubscription';
import {
  getEntitlementsCollection,
  getMailPlusEmail,
  isSubscribedToMailPlus,
  isSubscribedEssential
} from './linkAccounts/constantsAndUtils';

const PROFILE_PATH = '/registration/profile.html';

export const initProfile = async () => {
  const isLoggedIn = window.DM.isLoggedIn;
  const parsedUrl = new URL(window.location.href, true);
  const {pathname} = parsedUrl;
  const isPaywallEligible = window.getIsPaywallEligible && window.getIsPaywallEligible();

  if (!isLoggedIn || pathname !== PROFILE_PATH || !isPaywallEligible) {
    return;
  }

  const profileBoxEl = document.querySelector('.prfl-view-optn');

  if (!profileBoxEl) {
    return;
  }

  let entitlementFetchError = false;
  const [entitlement, user] = await Promise.all([
    getEntitlementFromApiWithRetry().catch(() => {
      entitlementFetchError = true;

      return null;
    }),
    getCurrentUserFromApi()
  ]);

  const mailplusEmail = getMailPlusEmail(entitlement);

  const entitlementList = getEntitlementsCollection(entitlement);
  const hasMailplus = isSubscribedToMailPlus(entitlementList);
  const hasEssential = isSubscribedEssential(entitlementList);
  const hasLinkedAccount = entitlement && entitlement.entitlements;

  const purchaseUrlFromConfig = getPurchaseUrl();
  const restorePurchaseUrl = getRestorePurchaseUrl();
  const manageSubscriptionUrl = getManageSubscriptionUrl();
  const parsedPurchaseUrl = purchaseUrlFromConfig && new URL(purchaseUrlFromConfig, true);
  const canRestorePurchase = !user || !user.migratedOneAccount;

  if (parsedPurchaseUrl) {
    parsedPurchaseUrl.query.molclicksource = 'account';
  }

  const purchaseUrl = parsedPurchaseUrl && parsedPurchaseUrl.toString();

  const mountElement = document.createElement('div');

  mountElement.classList.add('noskim');
  profileBoxEl.after(mountElement);

  render(
    <ProfileSubscription
      restorePurchaseUrl={restorePurchaseUrl}
      purchaseUrl={purchaseUrl}
      hasMailplus={hasMailplus}
      hasEssential={hasEssential}
      hasLinkedAccount={hasLinkedAccount}
      mailplusEmail={mailplusEmail}
      manageSubscriptionUrl={manageSubscriptionUrl}
      canRestorePurchase={canRestorePurchase}
      entitlementFetchError={entitlementFetchError}
    />,
    mountElement
  );
};
