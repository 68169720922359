/* eslint-disable complexity */
import {isArticleInRead} from '@mol-fe/mol-fe-article-read';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageCriteria, getPageMetadata, getPaywallGeo} from '@mol-fe/mol-fe-page-metadata';
import axios from 'axios';

// Period is in minutes
const PERIOD = 30;
const MAX_ARTICLES = 50;
const MAX_ARTICLES_SPORTS = 150;
const MAX_ARTICLES_HOME = 100;
const API_BASE_URL = '/api/mol-fe-feeds/v2/articles/rankedByChannelReferrer';
const API_PAYWALLED_URL = '/api/mol-fe-feeds/v2/articles/rankedPaywalled/withXpModules/true';
const API_BASE_HOME_PUFF = '/api/mol-fe-feeds/v2/articles/rankedForAutoPuff';
const API_RECENT_HOME_PUFF = '/api/mol-fe-feeds/v2/articles/recentForAutoPuff';
const AU_GEO = 'AU';
const AFL_SUB_CHANNEL = 'AFL';

const API_SUFFIX_BY_GEO = {
  AU: '/auhome',
  GB: '/home',
  US: '/ushome'
};

const getPaywallURLBaseOnChannel = (channel) =>
  `/api/mol-fe-feeds/v2/articles/rankedPaywalled/channel/${channel}/withXpModules/true`;

export const getPaywalledArticles = async ({channel = null, isUKHome = false} = {}) => {
  try {
    let fetchURL = channel && typeof channel === 'string' ?
      getPaywallURLBaseOnChannel(channel) : `${API_PAYWALLED_URL}/geo/${getPaywallGeo()}`;

    if (isUKHome) {
      fetchURL = `${API_BASE_HOME_PUFF}/geo/gb/paywalledOnly/true`;
    }

    const response = await axios.get(fetchURL);

    if (!response || !response.data || !response.data.articles) {
      return [];
    }

    const {articles} = response.data;

    const filteredArticles = isUKHome ? articles : articles
      .filter((article) => !isArticleInRead(article.articleId));

    if (filteredArticles.length >= 15) {
      return filteredArticles;
    }

    return articles;
  } catch (error) {
    logger.error('Error fetching paywalled articles for autopuff', error);
  }

  return [];
};

const aflSubChannelOutsideAustralia = (geo, article) => article.subChannel &&
  article.subChannel === AFL_SUB_CHANNEL &&
  geo !== AU_GEO;

export const getArticlesSport = async () => {
  const {articleId, geo} = getPageCriteria();

  const url = `/api/mol-fe-feeds/v2/articles/rankedByChannel/sport/geo/${getPaywallGeo()}/period/${PERIOD}/size/${MAX_ARTICLES_SPORTS}`;

  const response = await axios.get(url);

  if (!response || !response.data || !response.data.articles) {
    throw new Error('[mol-fe-auto-puff] Invalid sports ranked articles response');
  }

  const {articles} = response.data;

  return articles
    .filter((article) => !isArticleInRead(article.articleId) &&
      article.articleId !== articleId &&
      !aflSubChannelOutsideAustralia(geo, article));
};

const AGE_BRACKET_FIRST = 1000 * 60 * 60 * 5;
const AGE_BRACKET_FIRST_SIZE = 5;
const AGE_BRACKET_SECOND = 1000 * 60 * 60 * 12;
const AGE_BRACKET_SECOND_SIZE = 10;
const INSERT_RECENT_POSITIONS = [4, 6, 8];

export const getArticles = async ({isUKHome = false} = {}) => {
  const {articleId, geo} = getPageCriteria();
  const {domain, environment} = getPageMetadata();
  const suffix = `${API_SUFFIX_BY_GEO[geo] || API_SUFFIX_BY_GEO.GB}/withXpModules/true`;
  const prefixIfTim = domain === 'thisismoney' ? `https://www.dailymail${environment === 'production' ? '' : 'int'}.co.uk` : '';
  let url = `${prefixIfTim}${API_BASE_URL}${suffix}`;

  if (isUKHome) {
    url = `${prefixIfTim}${API_BASE_HOME_PUFF}/geo/gb`;
  }

  const response = await axios.get(url);

  if (!response || !response.data || !response.data.articles) {
    throw new Error('[mol-fe-auto-puff] Invalid ranked articles response');
  }

  const {articles} = response.data;

  if (!isUKHome) {
    return articles
      .filter((article) => !isArticleInRead(article.articleId) && article.articleId !== articleId)
      .slice(0, MAX_ARTICLES);
  }

  // uk home logic
  let firstAgeBracket = articles
    .filter((article) => Date.now() - new Date(article.firstPubDate).getTime() < AGE_BRACKET_FIRST).slice(0, AGE_BRACKET_FIRST_SIZE);

  if (firstAgeBracket.length < AGE_BRACKET_FIRST_SIZE) {
    firstAgeBracket = articles.slice()
      .sort((first, second) => new Date(first.firstPubDate).getTime() - new Date(second.firstPubDate).getTime())
      .slice(0, AGE_BRACKET_FIRST_SIZE);
  }

  const firstAgeBracketIds = firstAgeBracket.map((article) => article.articleId);
  const articlesWithoutFirstAgeBracket = articles.filter((article) => !firstAgeBracketIds.includes(article.articleId));

  let secondAgeBracket = articlesWithoutFirstAgeBracket
    .filter((article) => Date.now() - new Date(article.firstPubDate).getTime() < AGE_BRACKET_SECOND).slice(0, AGE_BRACKET_SECOND_SIZE);

  if (secondAgeBracket.length < 10) {
    secondAgeBracket = articlesWithoutFirstAgeBracket.slice()
      .sort((first, second) => new Date(first.firstPubDate).getTime() - new Date(second.firstPubDate).getTime())
      .slice(0, AGE_BRACKET_SECOND_SIZE);
  }

  const secondAgeBracketIds = secondAgeBracket.map((article) => article.articleId);
  const restOfArticles = articlesWithoutFirstAgeBracket.filter((article) => !secondAgeBracketIds.includes(article.articleId));

  const bracketedForUkHome = [...firstAgeBracket, ...secondAgeBracket, ...restOfArticles].slice(0, MAX_ARTICLES_HOME);

  try {
    const recentUrl = `${prefixIfTim}${API_RECENT_HOME_PUFF}/geo/gb`;
    const recentArticlesResponse = await axios.get(recentUrl);
    const {articles: recentArticles} = recentArticlesResponse.data;
    const bracketedIds = bracketedForUkHome.map((article) => article.articleId);

    if (recentArticles) {
      const filteredRecent = recentArticles.filter((article) => !bracketedIds.includes(article.articleId));
      let recentIndex = 0;

      for (const recentPositionToInsert of INSERT_RECENT_POSITIONS) {
        if (filteredRecent[recentIndex] && bracketedForUkHome[recentPositionToInsert]) {
          bracketedForUkHome.splice(recentPositionToInsert, 0, filteredRecent[recentIndex]);
          recentIndex++;
        }
      }
    }
  } catch (error) {
    logger.error('Error fetching recent articles for autopuff', error);
  }

  return bracketedForUkHome;
};
