import {getPageMetadata, getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import {logger} from '@mol-fe/mol-fe-client-logger';
import {later} from '@mol-fe/mol-fe-async';
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {renderPullNotification} from './pullNotification';
import {renderPrePrompt} from './prePrompt';
import getHulk from './getHulk';
import getOptions from './getOptions';
import {scrollThreshold} from './scrollThreshold';

const DEFAULT_PREPROMPT_DELAY_MS = 5000;

const isSafariGreaterThan16 = () => {
  const match = /Version\/([0-9.]+) ?Safari\/[0-9.]+/.exec(navigator.userAgent);

  if (match) {
    const version = parseFloat(match[1]);

    return !isNaN(version) && version >= 16;
  }

  return false;
};

// eslint-disable-next-line complexity
const loadRegister = async () => {
  try {
    const {webPush, articleId, contentType} = getPageMetadata();
    const {channel, isMobile, subchannel} = getPageCriteria();
    const {
      hostname,
      prePromptDelayTTL = DEFAULT_PREPROMPT_DELAY_MS,
      promptEnabled,
      pullNotificationEnabled,
      pullNotificationTTL = 5 * 60 * 1000,
      pullNotificationScrollThreshold
    } = webPush || {};
    const {Register} = await getHulk();
    const options = getOptions();
    const isEnabled = hostname && location.hostname.includes(hostname);

    if (isEnabled) {
      const instance = new Register(options);

      instance.on('error', logger.error);

      if (Register.isWebPushSupported()) {
        instance.on('notification_click', (event) =>
          pageEvents.publish(pageEvents.WEB_PUSH_NOTIFICATION_CLICK, event)
        );
        instance.on('prompt_pre_prompt_impression', (event) =>
          pageEvents.publish(pageEvents.WEB_PUSH_PRE_PROMPT_IMPRESSION, event)
        );
        instance.on('prompt_pre_prompt_click', (event) =>
          pageEvents.publish(pageEvents.WEB_PUSH_PRE_PROMPT_CLICK, event)
        );
        instance.on('prompt_impression', (event) =>
          pageEvents.publish(pageEvents.WEB_PUSH_PROMPT_IMPRESSION, event)
        );
        instance.on('prompt_click', (event) =>
          pageEvents.publish(pageEvents.WEB_PUSH_PROMPT_CLICK, event)
        );

        await instance.init();

        if (promptEnabled) {
          const showPrePrompt = !isMobile && isSafariGreaterThan16();

          setTimeout(() => {
            instance.showPrompt(showPrePrompt ? renderPrePrompt.bind(null, channel) : undefined);
          }, prePromptDelayTTL);
        }
      }

      if (pullNotificationEnabled && (contentType !== 'home' || channel !== subchannel || !['home', 'ushome', 'auhome'].includes(channel))) {
        instance.on('pull_notification_click', (event) =>
          pageEvents.publish(pageEvents.WEB_PULL_NOTIFICATION_CLICK, event)
        );

        await later('DOM_READY');

        if (typeof pullNotificationScrollThreshold === 'number' && pullNotificationScrollThreshold > 0) {
          await scrollThreshold(pullNotificationScrollThreshold, document);
        }

        await instance.initPullNotifications({
          canRender: (data) => typeof data === 'object' && (!data.assetId || data.assetId !== articleId),
          renderPullNotification: renderPullNotification.bind(null, pullNotificationTTL)
        });
      }
    }
  } catch (error) {
    logger.error(`Error initializing web push: ${error}`);
  }
};

export default loadRegister;
