/* eslint-disable import/max-dependencies */
import URL from 'url-parse';
import {later} from '@mol-fe/mol-fe-async';
import {getEntitlementAndStore} from '../api';
import {getFromStorage, setToStorage} from '../storage';
import renderNewUserModal from './renderNewUserModal';
import renderAccountsConnectedModal from './renderAccountsConnectedModal';
import renderLoggedInModal from './renderLoggedInModal';
import renderConfirmEmailModal from './renderConfirmEmailModal';
import renderOneAccountLoginSecondStepModal from './renderOneAccountLoginSecondStepModal';
import renderLoader from './renderLoader';
import {
  CAME_FROM_PURCHASE_MAIL_PLUS,
  CMP_KEY,
  MAIL_PLUS_ENTITLEMENT,
  ESSENTIAL_ENTITLEMENT,
  AD_LITE_ENTITLEMENT,
  cleanURL,
  getMailPlusEmail,
  getEntitlementsCollection,
  normaliseCameFromPurchase,
  handleConversionTrackingMailPlusProduct,
  getMountElement,
  handleUnmount
} from './constantsAndUtils';

const handlePurchaseOutcome = async (cameFromPurchase, purchaseOutcomeStatus) => {
  const mountElement = getMountElement();

  renderLoader(mountElement);

  const userData = await getEntitlementAndStore(true).catch(() => null);

  later.go('REFRESHED_PAYWALL_ENTITLEMENT');

  handleUnmount(mountElement);

  const entitlementsCollection = getEntitlementsCollection(userData);
  const mailPlusEmail = getMailPlusEmail(userData);

  const userHasMailPlus = entitlementsCollection.includes(MAIL_PLUS_ENTITLEMENT);
  const userHasEssential = entitlementsCollection.includes(ESSENTIAL_ENTITLEMENT);
  const userHasAdLite = entitlementsCollection.includes(AD_LITE_ENTITLEMENT);

  // handling tracking for mail plus subscription
  handleConversionTrackingMailPlusProduct(
    cameFromPurchase,
    purchaseOutcomeStatus,
    userHasMailPlus
  );

  // reset 'came-from-purchase' local storage state
  setToStorage('came-from-purchase', false);

  /* handle mail plus purchase outcome */
  switch (purchaseOutcomeStatus) {
  case 'newUser':
    renderNewUserModal(
      cameFromPurchase,
      userHasMailPlus,
      userHasEssential,
      userHasAdLite,
      mailPlusEmail
    );
    break;
  case 'activationNeeded':
    renderConfirmEmailModal(
      cameFromPurchase,
      userHasMailPlus,
      userHasEssential
    );
    break;
  case 'confirmSuccess':
    await renderAccountsConnectedModal(
      userHasMailPlus,
      userHasEssential,
      userHasAdLite,
      mailPlusEmail
    );
    break;
  case 'loggedIn':
    renderLoggedInModal(
      cameFromPurchase,
      userHasMailPlus,
      userHasEssential
    );
    break;
  }
};

export const initLinkedFlow = async () => {
  /** This value is set outside of async bundle
  * https://github.com/MailOnline/mol-fe-paywall-api/blob/f52cc2acf6bfa9522c913c143f7f3f936219a863/src/routers/mailplus/purchase.ts#L14
  * @type {{"product": 'essential' | 'mailPlus'} | true | undefined | null}
  */
  const cameFromPurchaseStorage = getFromStorage('came-from-purchase');
  const cameFromPurchase = normaliseCameFromPurchase(cameFromPurchaseStorage);

  const parsedUrl = new URL(window.location.href, true);

  /**
   * @type {'newUser' | 'confirmSuccess' | 'loggedIn' | 'activationNeeded' | undefined} purchaseOutcomeStatus
   *  - origin mol-registration-web service
   */
  const purchaseOutcomeStatus = parsedUrl.query.mailplusPurchaseOutcome;

  /**
   *  @type {string | undefined} showOALoginModalUrl should be a URL string
   * origin mol-registration-web service
   */
  const showOALoginModalUrl = parsedUrl.query.showOALoginModalUrl;

  /* handle One Account login redirect, this happens only on the registration page */
  if (showOALoginModalUrl) {
    renderOneAccountLoginSecondStepModal({showOALoginModalUrl});

    return;
  }

  if (cameFromPurchase === CAME_FROM_PURCHASE_MAIL_PLUS || !purchaseOutcomeStatus) {
    // Clean up storage key, if is a mailPlus purchase or is not having a purchase outcome,
    // then the CMP events for conversion are stale data
    localStorage.removeItem(CMP_KEY);
  }

  if (!purchaseOutcomeStatus) {
    return;
  }

  await handlePurchaseOutcome(cameFromPurchase, purchaseOutcomeStatus);

  cleanURL(purchaseOutcomeStatus, parsedUrl);
};
